export const rubrosHistoricos = [
    {
        "anio" : "2024",
        "descripcion" : "Disponibilidad de los trimestres anteriores",
        "trimestres" : [
            {
                "trimestre" : "Primer Trimestre",
                "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/48_Rubros/historicos/2024/48rubros1T2024.zip"
            },
            {
                "trimestre" : "Segundo Trimestre",
                "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/48_Rubros/historicos/2024/48rubros2T2024.zip"
            },
            /*{
                "trimestre" : "Tercer Trimestre",
                "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/48_Rubros/historicos/2024/48rubros3T2024.zip"
            },
            {
                "trimestre" : "Cuarto Trimestre",
                "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/48_Rubros/historicos/2024/48rubros4T2024.zip"
            },*/
            
        ]
    },
    {
        "anio" : "2023",
        "descripcion" : "Disponibilidad de los trimestres anteriores",
        "trimestres" : [
            {
                "trimestre" : "Primer Trimestre",
                "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/48_Rubros/2023/1t/48rubros1T2023.zip"
            },
            {
                "trimestre" : "Segundo Trimestre",
                "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/48_Rubros/2023/T2/48rubros2T2023.zip"
            },
            {
                "trimestre" : "Tercer Trimestre",
                "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/48_Rubros/2023/T3/48rubros3T2023.zip"
            },
            {
                "trimestre" : "Cuarto Trimestre",
                "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/48_Rubros/2023/4T/48rubros4T2023.zip"
            },
            
        ]
    },
    {
        "anio" : "2022",
        "descripcion" : "Disponibilidad de los trimestres anteriores",
        "trimestres" : [
            {
                "trimestre" : "Primer Trimestre",
                "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/48_Rubros/2022/PrimerTrimestre2022.zip"
            },
            {
                "trimestre" : "Segundo Trimestre",
                "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/48_Rubros/2022/SegundoTrimestre2022.zip"
            },
            {
                "trimestre" : "Tercer Trimestre",
                "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/48_Rubros/2022/TercerTrimestre2022.zip"
            },
            {
                "trimestre" : "Cuarto Trimestre",
                "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/48_Rubros/2022/CuartoTrimestre2022.zip"
            },
            
        ]
    },
    {
        "anio" : "2021",
        "descripcion" : "Disponibilidad de los trimestres anteriores",
        "trimestres" : [
            {
             "trimestre" : "Primer Trimestre",
                "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/48_Rubros/2021/PrimerTrimestre2021.zip"
            },
            {
                "trimestre" : "Segundo Trimestre",
                "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/48_Rubros/2021/SegundoTrimestre2021.zip"
            },
            {
                "trimestre" : "Tercer Trimestre",
                "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/48_Rubros/2021/TercerTrimestre2021.zip"
            },
            {
                "trimestre" : "Cuarto Trimestre",
                "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/48_Rubros/2021/CuartoTrimestre2021.zip"
            },
        ]
    },
    {
        "anio" : "2020",
        "descripcion" : "Disponibilidad de los trimestres anteriores",
        "trimestres" : [
            {
             "trimestre" : "Primer Trimestre",
                "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/48_Rubros/2020/PrimerTrimestre2020.zip"
            },
            {
                "trimestre" : "Segundo Trimestre",
                "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/48_Rubros/2020/SegundoTrimestre2020.zip"
            },
            {
                "trimestre" : "Tercer Trimestre",
                "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/48_Rubros/2020/TercerTrimestre2020.zip"
            },
            {
                "trimestre" : "Cuarto Trimestre",
                "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/48_Rubros/2020/CuartoTrimestre2020.zip"
            },
        ]
    },
    {
        "anio" : "2019",
        "descripcion" : "Disponibilidad de los trimestres anteriores",
        "trimestres" : [
            {
            "trimestre" : "Primer Trimestre",
                "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/48_Rubros/2019/PrimerTrimestre2019.zip"
            },
            {
                "trimestre" : "Segundo Trimestre",
                "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/48_Rubros/2019/SegundoTrimestre2019.zip"
            },
            {
                "trimestre" : "Tercer Trimestre",
                "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/48_Rubros/2019/TercerTrimestre2019.zip"
            },
            {
                "trimestre" : "Cuarto Trimestre",
                "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/48_Rubros/2019/CuartoTrimestre2019.zip"
            },
        ]
    },
    {
        "anio" : "2018",
        "descripcion" : "Disponibilidad de los trimestres anteriores",
        "trimestres" : [
            {
            "trimestre" : "Primer Trimestre",
                "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/48_Rubros/2018/PrimerTrimestre2018.zip"
            },
            {
                "trimestre" : "Segundo Trimestre",
                "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/48_Rubros/2018/SegundoTrimestre2018.zip"
            },
            {
                "trimestre" : "Tercer Trimestre",
                "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/48_Rubros/2018/TercerTrimestre2018.zip"
            },
            {
                "trimestre" : "Cuarto Trimestre",
                "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/48_Rubros/2018/CuartoTrimestre2018.zip"
            },
        ]
    },
    {
        "anio" : "2015 - 2017",
        "descripcion" : "Ejercicios Anteriores",
        "trimestres" : [
            {
                "trimestre" : "Plataforma Nacional",
                "url" : "www.google.com"
            }
        ]
    },
    {
        "anio" : "Información Histórica 21 Rubros",
        "descripcion" : "Ejercicios Anteriores",
        "trimestres" : [
            {
                "trimestre" : "Descargar",
                "url" : "www.google.com"
            }
        ]
    }
]